
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Escritorio",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/usuarios",
    "label": "Gestión de Usuarios",
    "icon": "pi pi-user-plus",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/empresas",
    "label": "Gestión de Empresas",
    "icon": "pi pi-building",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/festandar",
    "label": "Estudios de Seguridad",
    "icon": "pi pi-star-fill",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/adversos",
    "label": "Gestión de Adversos",
    "icon": "pi pi-table",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/adversos",
        "label": "Listado de Adversos",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tdelito",
        "label": "Edición de Delitos",
        "icon": "pi pi-caret-right",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/testado",
        "label": "Edición de Estado",
        "icon": "pi pi-caret-right",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/poligrafiadigital",
    "label": "Gestión Integral de  Poligrafías",
    "icon": "pi pi-bolt",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/poligrafiadigital",
        "label": "Poligrafía Examen de Rutina",
        "icon": "pi pi-bolt",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/poligrafiapreempleo",
        "label": "Poligrafia Examen Preempleo",
        "icon": "pi pi-bolt",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/poligrafiaexamenespecifico",
        "label": "Poligrafía Examen Específico",
        "icon": "pi pi-bolt",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/poligrafias",
        "label": "Poligrafía con Adjunto",
        "icon": "pi pi-bolt",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/analinum",
        "label": "Edición Análisis Númerico",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/visitadomi",
    "label": "Gestión de Visitas Domiciliarias",
    "icon": "pi pi-check-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tablaprincipal",
    "label": "Tabla Maestra",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	resideperso: [    
{value: "SI", label: "Si"},
	{value: "NO", label: "No"}
    ],
	estadocilvils: [    
{value: "Union Libre", label: "Union Libre"},
	{value: "Casado", label: "Casado"},
	{value: "Viudo", label: "Viudo"},
	{value: "Soltero", label: "Soltero"}
    ],
	tipoducment: [    
{value: "Cedula de Ciudadania", label: "Cédula De Ciudadanía"},
	{value: "Documento de Extranjeria", label: "Documento De Extranjería"},
	{value: "Permiso Especial", label: "Permiso Especial"},
	{value: "Pasaporte", label: "Pasaporte"}
    ],
	tiposolicitudd: [    
{value: "ConHuella", label: "Con Huella"},
	{value: "SinHuella", label: "Sin Huella"}
    ],
	sectoralque: [    
{value: "SectorFinanciero", label: "Sector Financiero"},
	{value: "Telcos", label: "Telcos"},
	{value: "SectorReal", label: "Sector Real"},
	{value: "SectorCooperativo", label: "Sector Cooperativo"}
    ],
	reportado: [    
{value: "Si", label: "Si"},
	{value: "No", label: "No"}
    ],
	nivelestudios: [    
{value: "Primaria", label: "Primaria"},
	{value: "Bachillerato", label: "Bachillerato"},
	{value: "Tecnico", label: "Técnico"},
	{value: "Tecnologico", label: "Tecnologico"},
	{value: "Universitario", label: "Universitario"},
	{value: "PostGrado", label: "Postgrado"},
	{value: "Otro", label: "Otro"}
    ],
	tipopoligrafia: [    
{value: "POLIGRAFO EXAMEN DE PRE EMPLEO", label: "POLIGRAFO EXAMEN DE PRE EMPLEO"},
	{value: "POLIGRAFO EXAMEN ESPECIFICO", label: "POLIGRAFO EXAMEN ESPECIFICO"},
	{value: "POLIGRAFO EXAMEN DE RUTINA", label: "POLIGRAFO EXAMEN DE RUTINA"}
    ],
	servicioquer: [    
{value: "Antecedentes Penales y Judiciales", label: "Antecedentes Penales Y Judiciales"},
	{value: "Verificación de referencias laborales", label: "Verificación de referencias laborales"},
	{value: "Verificación de referencias academicas ", label: "Verificación de referencias academicas"},
	{value: "Historial financiero con huella", label: "Historial financiero con huella"},
	{value: "Historial financiero sin huella", label: "Historial financiero sin huella"},
	{value: "Examen de polígrafo Pre empleo", label: "Examen de polígrafo Pre empleo"},
	{value: "Examen de polígrafo Rutina", label: "Examen de polígrafo Rutina"},
	{value: "Examen de polígrafo Especifico", label: "Examen de polígrafo Especifico"},
	{value: "Visita Domiciliaria Virtual", label: "Visita Domiciliaria Virtual"},
	{value: "Visita Domiciliaria presencial Bogotá", label: "Visita Domiciliaria presencial Bogotá"},
	{value: "Visita Domiciliaria presencial Nacional", label: "Visita Domiciliaria presencial Nacional"}
    ],
	estadosolicitud: [    
{value: "25", label: "Iniciado"},
	{value: "50", label: "En Proceso"},
	{value: "75", label: "Terminado"},
	{value: "100", label: "Informe Enviado"}
    ],
	estadocivil: [    
{value: "UnionLibre", label: "Unión Libre"},
	{value: "Viudo", label: "Viudo"},
	{value: "Divorciado", label: "Divorciado"},
	{value: "Casado", label: "Casado"},
	{value: "Soltero", label: "Soltero"}
    ],
	vehiculo: [    
{value: "Carro", label: "Carro"},
	{value: "Moto", label: "Moto"}
    ],
	tipov1: [    
{value: "Habitacion", label: "Habitacion"},
	{value: "Casa", label: "Casa"},
	{value: "Inquilinato", label: "Inquilinato"},
	{value: "Casalote", label: "Casalote"},
	{value: "Apartamento", label: "Apartamento"},
	{value: "Finca", label: "Finca"},
	{value: "Otro", label: "Otro"}
    ],
	usof1: [    
{value: "Familiar", label: "Familiar"},
	{value: "Propia", label: "Propia"},
	{value: "Cedida", label: "Cedida"},
	{value: "Invasion", label: "Invasion"},
	{value: "Arrendada", label: "Arrendada"},
	{value: "Sucesion", label: "Sucesion"},
	{value: "Otra", label: "Otra"}
    ],
	comodidades1: [    
{value: "Ventilada", label: "Ventilada"},
	{value: "Aseada", label: "Aseada"},
	{value: "Organizada", label: "Organizada"},
	{value: "Salubre", label: "Salubre"},
	{value: "Hacinamiento", label: "Hacinamiento"},
	{value: "Iluminada", label: "Iluminada"},
	{value: "Energia", label: "Energia"},
	{value: "Gas Natural", label: "Gas Natural"},
	{value: "Alcantarillado", label: "Alcantarillado"},
	{value: "Telefono", label: "Telefono"},
	{value: "Agua", label: "Agua"},
	{value: "Internet", label: "Internet"}
    ],
	areavecindario: [    
{value: "Rural", label: "Rural"},
	{value: "Residencial", label: "Residencial"},
	{value: "Popular", label: "Popular"},
	{value: "Comercial", label: "Comercial"},
	{value: "Invasion", label: "Invasión"},
	{value: "Otro", label: "Otro"}
    ],
	transporte: [    
{value: "Adecuado", label: "Adecuado"},
	{value: "Regular", label: "Regular"},
	{value: "Bueno", label: "Bueno"},
	{value: "Deficiente", label: "Deficiente"}
    ],
	confiableono: [    
{value: "CONFIABLE", label: "Confiable"},
	{value: "DESFAVORABLE", label: "Desfavorable"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}