
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//adversos routes
			{
				path: '/adversos/:fieldName?/:fieldValue?',
				name: 'adversoslist',
				component: () => import('./pages/adversos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/adversos/view/:id', 
				name: 'adversosview', 
				component: () => import('./pages/adversos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/adversos/add', 
				name: 'adversosadd', 
				component: () => import('./pages/adversos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/adversos/edit/:id', 
				name: 'adversosedit', 
				component: () => import('./pages/adversos/edit.vue'), 
				props: true
			},
		

//adversosrecienetes routes
			{
				path: '/adversosrecienetes/:fieldName?/:fieldValue?',
				name: 'adversosrecieneteslist',
				component: () => import('./pages/adversosrecienetes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//analinum routes
			{
				path: '/analinum/:fieldName?/:fieldValue?',
				name: 'analinumlist',
				component: () => import('./pages/analinum/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/analinum/view/:id', 
				name: 'analinumview', 
				component: () => import('./pages/analinum/view.vue'), 
				props: true
			},
		
			{ 
				path: '/analinum/add', 
				name: 'analinumadd', 
				component: () => import('./pages/analinum/add.vue'), 
				props: true
			},
	
			{ 
				path: '/analinum/edit/:id', 
				name: 'analinumedit', 
				component: () => import('./pages/analinum/edit.vue'), 
				props: true
			},
		

//audits routes
			{
				path: '/audits/:fieldName?/:fieldValue?',
				name: 'auditslist',
				component: () => import('./pages/audits/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/audits/view/:id', 
				name: 'auditsview', 
				component: () => import('./pages/audits/view.vue'), 
				props: true
			},
		

//ciudad routes
			{
				path: '/ciudad/:fieldName?/:fieldValue?',
				name: 'ciudadlist',
				component: () => import('./pages/ciudad/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ciudad/view/:id', 
				name: 'ciudadview', 
				component: () => import('./pages/ciudad/view.vue'), 
				props: true
			},
		
			{ 
				path: '/ciudad/add', 
				name: 'ciudadadd', 
				component: () => import('./pages/ciudad/add.vue'), 
				props: true
			},
	
			{ 
				path: '/ciudad/edit/:id', 
				name: 'ciudadedit', 
				component: () => import('./pages/ciudad/edit.vue'), 
				props: true
			},
		

//empresas routes
			{
				path: '/empresas/:fieldName?/:fieldValue?',
				name: 'empresaslist',
				component: () => import('./pages/empresas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/empresas/view/:id', 
				name: 'empresasview', 
				component: () => import('./pages/empresas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/empresas/add', 
				name: 'empresasadd', 
				component: () => import('./pages/empresas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/empresas/edit/:id', 
				name: 'empresasedit', 
				component: () => import('./pages/empresas/edit.vue'), 
				props: true
			},
		
			{
				path: '/empresas/empresaslistadootro/:fieldName?/:fieldValue?',
				name: 'empresasempresaslistadootro',
				component: () => import('./pages/empresas/empresaslistadootro.vue'), 
				props: route => passRouteToProps(route)
			},
	

//emprsaslistado routes
			{
				path: '/emprsaslistado/:fieldName?/:fieldValue?',
				name: 'emprsaslistadolist',
				component: () => import('./pages/emprsaslistado/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//estudiosrecientes routes
			{
				path: '/estudiosrecientes/:fieldName?/:fieldValue?',
				name: 'estudiosrecienteslist',
				component: () => import('./pages/estudiosrecientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//familiaresyamigosc routes
			{
				path: '/familiaresyamigosc/:fieldName?/:fieldValue?',
				name: 'familiaresyamigosclist',
				component: () => import('./pages/familiaresyamigosc/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/familiaresyamigosc/view/:id', 
				name: 'familiaresyamigoscview', 
				component: () => import('./pages/familiaresyamigosc/view.vue'), 
				props: true
			},
		
			{ 
				path: '/familiaresyamigosc/add', 
				name: 'familiaresyamigoscadd', 
				component: () => import('./pages/familiaresyamigosc/add.vue'), 
				props: true
			},
	
			{ 
				path: '/familiaresyamigosc/edit/:id', 
				name: 'familiaresyamigoscedit', 
				component: () => import('./pages/familiaresyamigosc/edit.vue'), 
				props: true
			},
		

//festandar routes
			{
				path: '/festandar/:fieldName?/:fieldValue?',
				name: 'festandarlist',
				component: () => import('./pages/festandar/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/festandar/view/:id', 
				name: 'festandarview', 
				component: () => import('./pages/festandar/view.vue'), 
				props: true
			},
		
			{ 
				path: '/festandar/add', 
				name: 'festandaradd', 
				component: () => import('./pages/festandar/add.vue'), 
				props: true
			},
	
			{ 
				path: '/festandar/edit/:id', 
				name: 'festandaredit', 
				component: () => import('./pages/festandar/edit.vue'), 
				props: true
			},
		

//hechosant routes
			{
				path: '/hechosant/:fieldName?/:fieldValue?',
				name: 'hechosantlist',
				component: () => import('./pages/hechosant/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/hechosant/view/:id', 
				name: 'hechosantview', 
				component: () => import('./pages/hechosant/view.vue'), 
				props: true
			},
		
			{ 
				path: '/hechosant/add', 
				name: 'hechosantadd', 
				component: () => import('./pages/hechosant/add.vue'), 
				props: true
			},
	
			{ 
				path: '/hechosant/edit/:id', 
				name: 'hechosantedit', 
				component: () => import('./pages/hechosant/edit.vue'), 
				props: true
			},
		

//historialfinanciero routes
			{
				path: '/historialfinanciero/:fieldName?/:fieldValue?',
				name: 'historialfinancierolist',
				component: () => import('./pages/historialfinanciero/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/historialfinanciero/view/:id', 
				name: 'historialfinancieroview', 
				component: () => import('./pages/historialfinanciero/view.vue'), 
				props: true
			},
		
			{ 
				path: '/historialfinanciero/add', 
				name: 'historialfinancieroadd', 
				component: () => import('./pages/historialfinanciero/add.vue'), 
				props: true
			},
	
			{ 
				path: '/historialfinanciero/edit/:id', 
				name: 'historialfinancieroedit', 
				component: () => import('./pages/historialfinanciero/edit.vue'), 
				props: true
			},
		

//infoacademica routes
			{
				path: '/infoacademica/:fieldName?/:fieldValue?',
				name: 'infoacademicalist',
				component: () => import('./pages/infoacademica/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/infoacademica/view/:id', 
				name: 'infoacademicaview', 
				component: () => import('./pages/infoacademica/view.vue'), 
				props: true
			},
		
			{ 
				path: '/infoacademica/add', 
				name: 'infoacademicaadd', 
				component: () => import('./pages/infoacademica/add.vue'), 
				props: true
			},
	
			{ 
				path: '/infoacademica/edit/:id', 
				name: 'infoacademicaedit', 
				component: () => import('./pages/infoacademica/edit.vue'), 
				props: true
			},
		

//infolaboral routes
			{
				path: '/infolaboral/:fieldName?/:fieldValue?',
				name: 'infolaborallist',
				component: () => import('./pages/infolaboral/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/infolaboral/view/:id', 
				name: 'infolaboralview', 
				component: () => import('./pages/infolaboral/view.vue'), 
				props: true
			},
		
			{ 
				path: '/infolaboral/add', 
				name: 'infolaboraladd', 
				component: () => import('./pages/infolaboral/add.vue'), 
				props: true
			},
	
			{ 
				path: '/infolaboral/edit/:id', 
				name: 'infolaboraledit', 
				component: () => import('./pages/infolaboral/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//policia routes
			{
				path: '/policia/:fieldName?/:fieldValue?',
				name: 'policialist',
				component: () => import('./pages/policia/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/policia/view/:id', 
				name: 'policiaview', 
				component: () => import('./pages/policia/view.vue'), 
				props: true
			},
		
			{ 
				path: '/policia/add', 
				name: 'policiaadd', 
				component: () => import('./pages/policia/add.vue'), 
				props: true
			},
	
			{ 
				path: '/policia/edit/:id', 
				name: 'policiaedit', 
				component: () => import('./pages/policia/edit.vue'), 
				props: true
			},
		

//poligrafiadigital routes
			{
				path: '/poligrafiadigital/:fieldName?/:fieldValue?',
				name: 'poligrafiadigitallist',
				component: () => import('./pages/poligrafiadigital/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/poligrafiadigital/view/:id', 
				name: 'poligrafiadigitalview', 
				component: () => import('./pages/poligrafiadigital/view.vue'), 
				props: true
			},
		
			{ 
				path: '/poligrafiadigital/add', 
				name: 'poligrafiadigitaladd', 
				component: () => import('./pages/poligrafiadigital/add.vue'), 
				props: true
			},
	
			{ 
				path: '/poligrafiadigital/edit/:id', 
				name: 'poligrafiadigitaledit', 
				component: () => import('./pages/poligrafiadigital/edit.vue'), 
				props: true
			},
		

//poligrafiaexamenespecifico routes
			{
				path: '/poligrafiaexamenespecifico/:fieldName?/:fieldValue?',
				name: 'poligrafiaexamenespecificolist',
				component: () => import('./pages/poligrafiaexamenespecifico/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/poligrafiaexamenespecifico/view/:id', 
				name: 'poligrafiaexamenespecificoview', 
				component: () => import('./pages/poligrafiaexamenespecifico/view.vue'), 
				props: true
			},
		
			{ 
				path: '/poligrafiaexamenespecifico/add', 
				name: 'poligrafiaexamenespecificoadd', 
				component: () => import('./pages/poligrafiaexamenespecifico/add.vue'), 
				props: true
			},
	
			{ 
				path: '/poligrafiaexamenespecifico/edit/:id', 
				name: 'poligrafiaexamenespecificoedit', 
				component: () => import('./pages/poligrafiaexamenespecifico/edit.vue'), 
				props: true
			},
		

//poligrafiapreempleo routes
			{
				path: '/poligrafiapreempleo/:fieldName?/:fieldValue?',
				name: 'poligrafiapreempleolist',
				component: () => import('./pages/poligrafiapreempleo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/poligrafiapreempleo/view/:id', 
				name: 'poligrafiapreempleoview', 
				component: () => import('./pages/poligrafiapreempleo/view.vue'), 
				props: true
			},
		
			{ 
				path: '/poligrafiapreempleo/add', 
				name: 'poligrafiapreempleoadd', 
				component: () => import('./pages/poligrafiapreempleo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/poligrafiapreempleo/edit/:id', 
				name: 'poligrafiapreempleoedit', 
				component: () => import('./pages/poligrafiapreempleo/edit.vue'), 
				props: true
			},
		

//poligrafias routes
			{
				path: '/poligrafias/:fieldName?/:fieldValue?',
				name: 'poligrafiaslist',
				component: () => import('./pages/poligrafias/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/poligrafias/view/:id', 
				name: 'poligrafiasview', 
				component: () => import('./pages/poligrafias/view.vue'), 
				props: true
			},
		
			{ 
				path: '/poligrafias/add', 
				name: 'poligrafiasadd', 
				component: () => import('./pages/poligrafias/add.vue'), 
				props: true
			},
	
			{ 
				path: '/poligrafias/edit/:id', 
				name: 'poligrafiasedit', 
				component: () => import('./pages/poligrafias/edit.vue'), 
				props: true
			},
		

//poligrarecientes routes
			{
				path: '/poligrarecientes/:fieldName?/:fieldValue?',
				name: 'poligrarecienteslist',
				component: () => import('./pages/poligrarecientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//preguntasrelevantes routes
			{
				path: '/preguntasrelevantes/:fieldName?/:fieldValue?',
				name: 'preguntasrelevanteslist',
				component: () => import('./pages/preguntasrelevantes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/preguntasrelevantes/view/:id', 
				name: 'preguntasrelevantesview', 
				component: () => import('./pages/preguntasrelevantes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/preguntasrelevantes/add', 
				name: 'preguntasrelevantesadd', 
				component: () => import('./pages/preguntasrelevantes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/preguntasrelevantes/edit/:id', 
				name: 'preguntasrelevantesedit', 
				component: () => import('./pages/preguntasrelevantes/edit.vue'), 
				props: true
			},
		

//procuraduria routes
			{
				path: '/procuraduria/:fieldName?/:fieldValue?',
				name: 'procuradurialist',
				component: () => import('./pages/procuraduria/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/procuraduria/view/:id', 
				name: 'procuraduriaview', 
				component: () => import('./pages/procuraduria/view.vue'), 
				props: true
			},
		
			{ 
				path: '/procuraduria/add', 
				name: 'procuraduriaadd', 
				component: () => import('./pages/procuraduria/add.vue'), 
				props: true
			},
	
			{ 
				path: '/procuraduria/edit/:id', 
				name: 'procuraduriaedit', 
				component: () => import('./pages/procuraduria/edit.vue'), 
				props: true
			},
		

//ramajudicial routes
			{
				path: '/ramajudicial/:fieldName?/:fieldValue?',
				name: 'ramajudiciallist',
				component: () => import('./pages/ramajudicial/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ramajudicial/view/:id', 
				name: 'ramajudicialview', 
				component: () => import('./pages/ramajudicial/view.vue'), 
				props: true
			},
		
			{ 
				path: '/ramajudicial/add', 
				name: 'ramajudicialadd', 
				component: () => import('./pages/ramajudicial/add.vue'), 
				props: true
			},
	
			{ 
				path: '/ramajudicial/edit/:id', 
				name: 'ramajudicialedit', 
				component: () => import('./pages/ramajudicial/edit.vue'), 
				props: true
			},
		

//refeducativa routes
			{
				path: '/refeducativa/:fieldName?/:fieldValue?',
				name: 'refeducativalist',
				component: () => import('./pages/refeducativa/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/refeducativa/view/:id', 
				name: 'refeducativaview', 
				component: () => import('./pages/refeducativa/view.vue'), 
				props: true
			},
		
			{ 
				path: '/refeducativa/add', 
				name: 'refeducativaadd', 
				component: () => import('./pages/refeducativa/add.vue'), 
				props: true
			},
	
			{ 
				path: '/refeducativa/edit/:id', 
				name: 'refeducativaedit', 
				component: () => import('./pages/refeducativa/edit.vue'), 
				props: true
			},
		

//reflaborales routes
			{
				path: '/reflaborales/:fieldName?/:fieldValue?',
				name: 'reflaboraleslist',
				component: () => import('./pages/reflaborales/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/reflaborales/view/:id', 
				name: 'reflaboralesview', 
				component: () => import('./pages/reflaborales/view.vue'), 
				props: true
			},
		
			{ 
				path: '/reflaborales/add', 
				name: 'reflaboralesadd', 
				component: () => import('./pages/reflaborales/add.vue'), 
				props: true
			},
	
			{ 
				path: '/reflaborales/edit/:id', 
				name: 'reflaboralesedit', 
				component: () => import('./pages/reflaborales/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//simit routes
			{
				path: '/simit/:fieldName?/:fieldValue?',
				name: 'simitlist',
				component: () => import('./pages/simit/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/simit/view/:id', 
				name: 'simitview', 
				component: () => import('./pages/simit/view.vue'), 
				props: true
			},
		
			{ 
				path: '/simit/add', 
				name: 'simitadd', 
				component: () => import('./pages/simit/add.vue'), 
				props: true
			},
	
			{ 
				path: '/simit/edit/:id', 
				name: 'simitedit', 
				component: () => import('./pages/simit/edit.vue'), 
				props: true
			},
		

//solicitudservicios routes
			{
				path: '/solicitudservicios/:fieldName?/:fieldValue?',
				name: 'solicitudservicioslist',
				component: () => import('./pages/solicitudservicios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/solicitudservicios/view/:id', 
				name: 'solicitudserviciosview', 
				component: () => import('./pages/solicitudservicios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/solicitudservicios/add', 
				name: 'solicitudserviciosadd', 
				component: () => import('./pages/solicitudservicios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/solicitudservicios/edit/:id', 
				name: 'solicitudserviciosedit', 
				component: () => import('./pages/solicitudservicios/edit.vue'), 
				props: true
			},
		

//tablaprincipal routes
			{
				path: '/tablaprincipal/:fieldName?/:fieldValue?',
				name: 'tablaprincipallist',
				component: () => import('./pages/tablaprincipal/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tcalidad routes
			{
				path: '/tcalidad/:fieldName?/:fieldValue?',
				name: 'tcalidadlist',
				component: () => import('./pages/tcalidad/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tcalidad/view/:id', 
				name: 'tcalidadview', 
				component: () => import('./pages/tcalidad/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tcalidad/add', 
				name: 'tcalidadadd', 
				component: () => import('./pages/tcalidad/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tcalidad/edit/:id', 
				name: 'tcalidadedit', 
				component: () => import('./pages/tcalidad/edit.vue'), 
				props: true
			},
		

//tdelito routes
			{
				path: '/tdelito/:fieldName?/:fieldValue?',
				name: 'tdelitolist',
				component: () => import('./pages/tdelito/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tdelito/view/:id', 
				name: 'tdelitoview', 
				component: () => import('./pages/tdelito/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tdelito/add', 
				name: 'tdelitoadd', 
				component: () => import('./pages/tdelito/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tdelito/edit/:id', 
				name: 'tdelitoedit', 
				component: () => import('./pages/tdelito/edit.vue'), 
				props: true
			},
		

//testado routes
			{
				path: '/testado/:fieldName?/:fieldValue?',
				name: 'testadolist',
				component: () => import('./pages/testado/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/testado/view/:id', 
				name: 'testadoview', 
				component: () => import('./pages/testado/view.vue'), 
				props: true
			},
		
			{ 
				path: '/testado/add', 
				name: 'testadoadd', 
				component: () => import('./pages/testado/add.vue'), 
				props: true
			},
	
			{ 
				path: '/testado/edit/:id', 
				name: 'testadoedit', 
				component: () => import('./pages/testado/edit.vue'), 
				props: true
			},
		

//usuarios routes
			{
				path: '/usuarios/:fieldName?/:fieldValue?',
				name: 'usuarioslist',
				component: () => import('./pages/usuarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuarios/view/:id', 
				name: 'usuariosview', 
				component: () => import('./pages/usuarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usuariosuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usuariosaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuariosaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/add', 
				name: 'usuariosadd', 
				component: () => import('./pages/usuarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/edit/:id', 
				name: 'usuariosedit', 
				component: () => import('./pages/usuarios/edit.vue'), 
				props: true
			},
		

//usuariosrelist routes
			{
				path: '/usuariosrelist/:fieldName?/:fieldValue?',
				name: 'usuariosrelistlist',
				component: () => import('./pages/usuariosrelist/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//visitadomi routes
			{
				path: '/visitadomi/:fieldName?/:fieldValue?',
				name: 'visitadomilist',
				component: () => import('./pages/visitadomi/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/visitadomi/view/:id', 
				name: 'visitadomiview', 
				component: () => import('./pages/visitadomi/view.vue'), 
				props: true
			},
		
			{ 
				path: '/visitadomi/add', 
				name: 'visitadomiadd', 
				component: () => import('./pages/visitadomi/add.vue'), 
				props: true
			},
	
			{ 
				path: '/visitadomi/edit/:id', 
				name: 'visitadomiedit', 
				component: () => import('./pages/visitadomi/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		const roleName = auth.userRole.toLowerCase();
		let rolePage;
		switch(roleName){
			case "admin":
				rolePage = "admin";
				break;
			case "empresa":
				rolePage = "empresa";
				break;
			case "operadoruss":
				rolePage = "operadoruss";
				break;
			case "gestionuss":
				rolePage = "gestionuss";
				break;
			default:
				rolePage = "home";
		}
		
		//Dashboard route
		//Display page based on user role
		routes.push({
			path: '/',
			alias: '/home', 
			name: 'home', 
			component: () => import(`./pages/home/${rolePage}.vue`),
			props: true
		});
	}
	else{
		routes.push({
			path: '/', 
			alias: '/index', 
			name: 'index', 
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
